import {ControllerParams, TFunction} from '@wix/yoshi-flow-editor';
import {
  SiteStore,
  cashierExpressAddressToEcomAddress,
  CashierCheckoutDataApi,
  ICashierCheckoutDataApi,
  CheckoutNavigationService,
  CheckoutInfo,
  QueryParamsService,
  PlaceOrderResponse,
  DynamicPaymentMethodsShape,
  DynamicPaymentMethodsTheme,
  StoreMetaDataApi,
} from '@wix/wixstores-client-storefront-sdk';
import {
  APP_DEFINITION_ID,
  STORES_APP_DEF_ID,
  PageMap,
  ServerTransactionStatus,
  StoresWidgetID,
} from '@wix/wixstores-client-core';
import {WithResultObservation} from '../../hooks/useFunctionResultObservation.worker';
import {
  OnPaymentAuthorizedResult,
  OnShippingContactSelected,
  OnShippingMethodSelected,
  PaymentAuthorizedArgs,
} from '@wix/cashier-express-checkout-widget/src/types/ExternalContract';
import {CartService} from '../services/CartService';
import {ShippingError} from '@wix/cashier-express-checkout-widget/dist/src/types/Shipping';
import {BreakdownTypes, PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {CreateCheckoutExceptions, OriginTypes, PaymentMethodType} from '../../components/cart/constants';
import {ButtonStyle, Theme} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {BIService} from '../services/BIService';
import {OrderService} from '../services/OrderService';
import {FetchContext, ICart, ICartControllerApi, ICheckoutShippingRuleOptions} from '../../types/app.types';
import {NavigationService} from '../services/NavigationService';
import {ModalManagerService} from '../services/ModalManagerService';
import {SPECS} from '../specs';
import _, {get} from 'lodash';
import {ElementOf} from 'ts-essentials';
import {hasFreeText} from '../utils/itemUtils';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {ShippingMethod} from '@wix/cashier-express-checkout-widget/src/types/Shipping';
import {round} from '../utils/math.utils';
import {createExpressCheckoutButtonsController} from '@wix/ecom-platform-express-checkout-buttons';
import {handleCreateCheckoutErrors} from '../utils/handleCreateCheckoutErrors';

export interface PaymentBreakdownAndAmount {
  paymentBreakdown: PaymentBreakdown;
  paymentAmount: string;
}

export class CashierExpressStore {
  private readonly cartService: CartService;
  private readonly navigationService: NavigationService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly controllerApi: ICartControllerApi;
  private readonly biService: BIService;
  private readonly orderService: OrderService;
  private readonly modalManagerService: ModalManagerService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private countryCodes: ICashierCheckoutDataApi['countries'];
  private isTermsAndConditionsEnabled: boolean;
  private isCustomFieldRequired: boolean;
  private cashierExpressWidgetShippingRuleId: string;
  private storeMetaData: Awaited<ReturnType<StoreMetaDataApi['getStoreMetaData']>>;

  constructor(
    controllerParams: ControllerParams,
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      cartService,
      checkoutNavigationService,
      storeMetaDataService,
      biService,
      orderService,
      navigationService,
      modalManagerService,
      minimumOrderAmountService,
    }: {
      cartService: CartService;
      checkoutNavigationService: CheckoutNavigationService;
      storeMetaDataService: StoreMetaDataService;
      biService: BIService;
      orderService: OrderService;
      navigationService: NavigationService;
      modalManagerService: ModalManagerService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    private readonly styleSettingsService: StyleSettingsService,
    private readonly withResultObservation: WithResultObservation,
    private readonly t: TFunction
  ) {
    this.controllerApi = controllerApi;
    this.cartService = cartService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.navigationService = navigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.orderService = orderService;
    this.biService = biService;
    this.modalManagerService = modalManagerService;
    this.minimumOrderAmountService = minimumOrderAmountService;
    this.t = t;

    if (this.siteStore.experiments?.enabled(SPECS.UseExpressCheckoutButtonsLibraryForCart)) {
      void this.initializeExpressCheckoutButtonsController(controllerParams);
    }
  }

  private readonly initializeExpressCheckoutButtonsController = async (controllerParams: ControllerParams) => {
    this.storeMetaData = await this.storeMetaDataService.get();

    const controller = createExpressCheckoutButtonsController({
      getCurrency: () => this.siteStore.getCurrentCurrency(),
      demoMode: this.siteStore.isEditorMode() || this.siteStore.isPreviewMode(),
      controllerConfig: controllerParams.controllerConfig,
      httpClient: controllerParams.flowAPI.httpClient,
      onClick: () => {
        const {activePaymentMethods} = this.storeMetaData;
        this.navigationService.isNavigationToCheckoutInProcess = true;

        const isPickupFlow = this.orderService.isPickup;
        const isWithOfflinePayment =
          activePaymentMethods?.filter((paymentMethod) => paymentMethod.type === PaymentMethodType.offline).length > 0;
        this.biService.clickOnCheckoutWithEWallet(
          this.cartService.cart,
          this.cartService.cartType,
          activePaymentMethods,
          isWithOfflinePayment,
          isPickupFlow
        );

        const {canCheckout, modalType} = this.checkIsAllowedToCheckout();
        if (!canCheckout) {
          void this.checkoutNavigationService.openModalByType(
            modalType,
            this.styleSettingsService.isEditorX,
            this.cartService.cart
          );
          this.navigationService.isNavigationToCheckoutInProcess = false;
        }
      },
      getCheckoutId: /* istanbul ignore next */ async () => {
        await this.fetchCashierCheckoutDataIfNeeded();
        const createCheckoutResult = await this.cartService.createCheckout();
        if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
          await handleCreateCheckoutErrors(
            this.checkoutNavigationService,
            this.styleSettingsService.isEditorX,
            this.cartService.cart,
            createCheckoutResult.error as CreateCheckoutExceptions
          );

          this.navigationService.isNavigationToCheckoutInProcess = false;
          return Promise.resolve(undefined);
        } else {
          setTimeout(() => (this.navigationService.isNavigationToCheckoutInProcess = false), 5_000);
          return Promise.resolve(this.cartService.checkoutId);
        }
      },
      widgetId: StoresWidgetID.CART,
    });
    void controller.init();
  };

  private readonly checkIsAllowedToCheckout = () => {
    const {hasCreatedPaymentMethods, canStoreShip, isPremium} = this.storeMetaData ?? /* istanbul ignore next */ {};

    const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isNonShippableCart,
      hasShippableItems: this.cartService.hasShippableItems,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: true,
    });
    return {canCheckout, modalType};
  };

  private get cashierExpressEnabled() {
    return this.cartService.areAllItemsInStock;
  }

  private readonly fetchCashierCheckoutDataIfNeeded = async () => {
    if (this.countryCodes) {
      return;
    }
    const response = await new CashierCheckoutDataApi({siteStore: this.siteStore, origin: ''}).getCashierCheckoutData();
    this.countryCodes = response.countries;
    this.isTermsAndConditionsEnabled = response.termsAndConditions?.enabled;
    this.isCustomFieldRequired = response.checkoutCustomField?.show && response.checkoutCustomField.mandatory;
  };

  private readonly handlePaymentMethodError = () => {
    const queryParamsService = new QueryParamsService(this.siteStore);
    const pmName = queryParamsService.getQueryParam('pmName');
    const pmUrl = queryParamsService.getQueryParam('pmUrl') ?? '';

    if (pmName !== undefined && pmName !== '') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.modalManagerService.modalManger.openErrorWithPaymentMethod({
        pmName: pmName.replace(/\//g, ''),
        pmUrl: pmUrl.replace(/\//g, ''),
      });
    }
  };

  private readonly onClick = async () => {
    this.navigationService.isNavigationToCheckoutInProcess = true;

    await this.fetchCashierCheckoutDataIfNeeded();

    const paymentMethods = (await this.storeMetaDataService.get()).activePaymentMethods;
    const isWithOfflinePayment = await this.storeMetaDataService.hasOfflinePaymentMethods();
    const isPickupFlow = this.orderService.isPickup;
    this.biService.clickOnCheckoutWithEWallet(
      this.cartService.cart,
      this.cartService.cartType,
      paymentMethods,
      isWithOfflinePayment,
      isPickupFlow
    );

    const {hasCreatedPaymentMethods, canStoreShip, isPremium} = await this.storeMetaDataService.get();
    const {canCheckout, modalType} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isNonShippableCart,
      hasShippableItems: this.cartService.hasShippableItems,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: true,
    });

    if (!canCheckout) {
      void this.checkoutNavigationService.openModalByType(
        modalType,
        this.styleSettingsService.isEditorX,
        this.cartService.cart
      );
      this.navigationService.isNavigationToCheckoutInProcess = false;
      return Promise.resolve({canceled: true});
    }

    const createCheckoutResult = await this.cartService.createCheckout();

    if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
      await handleCreateCheckoutErrors(
        this.checkoutNavigationService,
        this.styleSettingsService.isEditorX,
        this.cartService.cart,
        createCheckoutResult.error as CreateCheckoutExceptions
      );

      this.navigationService.isNavigationToCheckoutInProcess = false;
      return Promise.resolve({canceled: true});
    } else {
      setTimeout(() => (this.navigationService.isNavigationToCheckoutInProcess = false), 5_000);
      return Promise.resolve({canceled: false});
    }
  };

  private readonly onShippingContactSelected: OnShippingContactSelected = async (shippingContact) => {
    let shippingContactSelected;
    const {country, subdivision, zipCode} = cashierExpressAddressToEcomAddress(shippingContact, {}, this.countryCodes);
    const cartId = this.cartService.cart.cartId;
    const checkoutForFastFlow = await this.cartService.setShippingAddressesForFastFlow({
      cartId,
      country,
      subdivision,
      zipCode,
    });
    const {destinationCompleteness, totals, shippingRuleInfo, selectedShippingOption} =
      checkoutForFastFlow.cartService.cart;
    const {total, itemsTotal, shipping, tax, discount, additionalFeesTotal} = totals;

    const isShowApplePayShipping = this.siteStore.experiments.enabled(SPECS.showShippingMethodCashierExpressButton);
    const notEnoughInfoAboutSubdivision = destinationCompleteness.includes('SUBDIVISION');
    const {paymentAmount, paymentBreakdown} = this.getPaymentBreakdownAndAmount({
      total,
      itemsTotal,
      shipping,
      tax,
      discount,
      additionalFeesTotal,
    });
    const hasShippingRules = this.orderService.hasShippingRules;

    if (isShowApplePayShipping) {
      const shippingMethods: ShippingMethod[] = this.getShippingMethods(
        shippingRuleInfo as ICart['shippingRuleInfo'],
        selectedShippingOption
      );

      shippingContactSelected = {
        paymentAmount,
        paymentBreakdown,
        shippingMethods,
      };
    } else {
      shippingContactSelected = {
        paymentAmount,
        paymentBreakdown,
      };
    }

    /* istanbul ignore else */
    if (shippingRuleInfo.canShipToDestination || notEnoughInfoAboutSubdivision || hasShippingRules) {
      return shippingContactSelected;
    } else {
      return {error: ShippingError.SHIPPING_ADDRESS_UNSERVICEABLE};
    }
  };

  private readonly getShippingMethods = (
    shippingRuleInfo: ICart['shippingRuleInfo'],
    selectedShippingOption: ICart['selectedShippingOption']
  ): ShippingMethod[] => {
    this.cashierExpressWidgetShippingRuleId = get(shippingRuleInfo, 'shippingRule.id', null);
    let shippingRuleOptions: ICheckoutShippingRuleOptions = _.get(shippingRuleInfo, 'shippingRule.options', []);

    /* istanbul ignore else */
    if (selectedShippingOption) {
      const selectedShippingRuleOption = shippingRuleOptions.find((option) => option.id === selectedShippingOption.id);

      const filteredShippingOptions = shippingRuleOptions.filter((option) => option.id !== selectedShippingOption.id);

      shippingRuleOptions = [selectedShippingRuleOption, ...filteredShippingOptions];
    }

    return shippingRuleOptions.map((shippingMethod) => {
      return {
        label: shippingMethod.title,
        amount: get(shippingMethod, 'rate', 0).toString(),
        identifier: shippingMethod.id,
        detail:
          (shippingMethod.pickupInfo
            ? this.t('cart.delivery_method_picker.pickup_address', {
                addressLine: get(shippingMethod, 'pickupInfo.address.addressLine', null),
                city: get(shippingMethod, 'pickupInfo.address.city', null),
                subdivision: get(shippingMethod, 'pickupInfo.address.subdivisionName', null),
                country: get(shippingMethod, 'pickupInfo.address.countryName', null),
              })
            : shippingMethod.deliveryTime) || '',
      };
    });
  };

  public onShippingMethodSelected: OnShippingMethodSelected = async (shippingMethod: ShippingMethod) => {
    const selectedShippingOption = {
      shippingRuleId: this.cashierExpressWidgetShippingRuleId,
      optionId: shippingMethod.identifier,
    };

    const fn = () => this.cartService.setShippingOption(this.cartService.cart.cartId, selectedShippingOption);
    await this.controllerApi.executeWithLoader(fn, FetchContext.SelectShippingOption);

    const cart = await this.cartService.fetchCartWithTaxAndShippingIncluded(FetchContext.ShippingContactSelected);
    const {total, itemsTotal, shipping, tax, discount, additionalFeesTotal} = cart.totals;
    const {paymentAmount, paymentBreakdown} = this.getPaymentBreakdownAndAmount({
      total,
      itemsTotal,
      shipping,
      tax,
      discount,
      additionalFeesTotal,
    });

    return {paymentAmount, paymentBreakdown};
  };

  private readonly getPaymentBreakdownAndAmount = ({
    total,
    itemsTotal,
    shipping,
    tax,
    discount,
    additionalFeesTotal,
  }: {
    total: number;
    itemsTotal: number;
    shipping: number;
    tax: number;
    discount: number;
    additionalFeesTotal: number;
  }): PaymentBreakdownAndAmount => {
    const {taxOnProduct} = this.orderService;
    const paymentAmount = total.toString();

    const additionalFeesTotalCost = additionalFeesTotal || 0;
    const itemsTotalWithAdditionalFees = round(itemsTotal + additionalFeesTotalCost);
    const paymentBreakdown: PaymentBreakdown = {
      [BreakdownTypes.ItemsTotal]: itemsTotalWithAdditionalFees.toString(),
      [BreakdownTypes.Shipping]: shipping.toString(),
      [BreakdownTypes.Tax]: taxOnProduct ? /* istanbul ignore next */ '0' : tax.toString(),
      [BreakdownTypes.Discount]: discount.toString(),
    };

    return {paymentAmount, paymentBreakdown};
  };

  private readonly onPaymentAuthorized = async (
    paymentInfo: PaymentAuthorizedArgs,
    accessibilityEnabled: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ): Promise<OnPaymentAuthorizedResult> => {
    /* istanbul ignore if - can't test with current cashier testKit */
    if (paymentInfo.error) {
      throw paymentInfo.error;
    }

    const paymentMethods = (await this.storeMetaDataService.get()).activePaymentMethods;

    this.biService.clickContinueEWalletModalSf(this.cartService.cart, this.cartService.cartType, paymentMethods);
    this.cartService.trackInitiateCheckout();

    const shouldRequestShipping = this.shouldRequestShipping();

    if (this.siteStore.experiments.enabled(SPECS.PaypalUpdateShippingAndBilling)) {
      const setShipping = shouldRequestShipping
        ? this.cartService.setCartShippingAddressAndDestination(
            this.cartService.cart.cartId,
            cashierExpressAddressToEcomAddress(
              paymentInfo.shippingContact,
              paymentInfo.billingContact,
              this.countryCodes
            )
          )
        : Promise.resolve();

      await setShipping;
      await this.cartService.setCartBillingAddress(
        this.cartService.cart.cartId,
        cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes)
      );
    } else {
      // eslint-disable-next-line no-lonely-if
      if (shouldRequestShipping) {
        await this.cartService.setCartAddress({
          cartId: this.cartService.cart.cartId,
          address: cashierExpressAddressToEcomAddress(
            paymentInfo.shippingContact,
            paymentInfo.billingContact,
            this.countryCodes
          ),
        });
      } else {
        await this.cartService.setCartBillingAddress(
          this.cartService.cart.cartId,
          cashierExpressAddressToEcomAddress({}, paymentInfo.billingContact, this.countryCodes)
        );
      }
    }
    const checkout = await this.cartService.fetchCartLegacyOrCheckoutPlatform(FetchContext.PaymentAuthorized);
    const notEnoughInfoAboutSubdivision = checkout.destinationCompleteness.includes('SUBDIVISION');
    const onlyOneShippingMethod = _.get(checkout, 'shippingRuleInfo.shippingRule.options', []).length === 1;
    const missingShippingData = shouldRequestShipping && !onlyOneShippingMethod;
    const isCustomFieldRequiredWithExperiment = this.isCustomFieldRequired;
    const canPayWithoutNavigatingToCheckout =
      !missingShippingData &&
      !this.isTermsAndConditionsEnabled &&
      !notEnoughInfoAboutSubdivision &&
      !isCustomFieldRequiredWithExperiment;

    if (canPayWithoutNavigatingToCheckout) {
      const placeOrderResponse = await this.cartService.placeOrder({
        cartId: checkout.cartId,
        paymentId: paymentInfo.detailsId,
        shouldRedirect: true,
        isPickupFlow: false,
        inUserDomain: true,
        forceLocale: this.siteStore.locale,
        deviceType: this.siteStore.isMobile() ? /* istanbul ignore next */ 'mobile' : 'desktop',
        ...(this.cartService.checkoutId ? /* istanbul ignore next */ {checkoutId: this.cartService.checkoutId} : {}),
      });
      const wasPlaceOrderSuccessful = placeOrderResponse.cartStatus.success;
      const isFailedTransaction = this.isNotValidTransaction(placeOrderResponse);

      if (!wasPlaceOrderSuccessful || isFailedTransaction) {
        //log errors
        return {result: 'error'};
      }

      void this.siteStore.navigate(
        {
          sectionId: PageMap.THANKYOU,
          queryParams: {objectType: 'order', origin: 'cashier-express-directly-to-typ'},
          state: placeOrderResponse.orderId,
        },
        true
      );
    } else {
      const navigationArgs: CheckoutInfo = {
        a11y: accessibilityEnabled,
        cartId: checkout.cartId,
        cashierPaymentId: paymentInfo.detailsId,
        deviceType: this.siteStore.isMobile() ? /* istanbul ignore next */ 'mobile' : 'desktop',
        isPickupOnly: !this.shouldRequestShipping(),
        locale: this.siteStore.locale,
        originType: OriginTypes.Paypal,
        siteBaseUrl: this.siteStore.location.baseUrl,
        ...(this.cartService.checkoutId ? {checkoutId: this.cartService.checkoutId} : {}),
      };

      /* istanbul ignore if */
      if (
        !this.siteStore.experiments.enabled(SPECS.UseNewCheckoutForNonStoresFlows) &&
        !hasOnlyStoresItems(this.cartService.cart)
      ) {
        /* istanbul ignore next */
        navigationArgs.forceIframe = true;
      }

      await this.checkoutNavigationService.navigateToCheckout(navigationArgs);
    }

    return {result: 'success'};
  };

  private isNotValidTransaction(placeOrderResponse: PlaceOrderResponse): boolean {
    const isFailedStatus = placeOrderResponse.paymentResponse.transactionStatus === ServerTransactionStatus.Failed;
    const isDeclinedStatus = placeOrderResponse.paymentResponse.transactionStatus === ServerTransactionStatus.Declined;
    return isFailedStatus || isDeclinedStatus;
  }

  private shouldRequestShipping(): boolean {
    const isNonShippableCart = this.cartService.isNonShippableCart;
    const isPickup = this.orderService.isPickup;
    const hasShippableItems = this.cartService.hasShippableItems;
    return this.siteStore.experiments.enabled(SPECS.DisplayMixedCart)
      ? hasShippableItems && !isPickup
      : !(isNonShippableCart || isPickup);
  }

  private getButtonStyle(): ButtonStyle {
    return {
      shape: this.styleSettingsService.dynamicPaymentMethodsShape === DynamicPaymentMethodsShape.pill ? 'pill' : 'rect',
      height: 42,
    };
  }

  private getButtonTheme(): Theme {
    return this.styleSettingsService.dynamicPaymentMethodsTheme === DynamicPaymentMethodsTheme.light ? 'light' : 'dark';
  }

  private getProps() {
    return {
      handlePaymentMethodError: this.handlePaymentMethodError,
      shouldShowDynamicPaymentOptions:
        !this.minimumOrderAmountService.shouldDisableCheckout && !this.cartService?.cart?.totals.payLater,
      ...this.withResultObservation({
        onClick: this.onClick,
        onShippingContactSelected: this.onShippingContactSelected,
        onShippingMethodSelected: this.onShippingMethodSelected,
        onPaymentAuthorized: this.onPaymentAuthorized,
      }),
      shouldShowShippingMethod: this.siteStore.experiments.enabled(SPECS.showShippingMethodCashierExpressButton),
      dynamicPaymentOptionsProps: {
        currency: this.cartService?.cart?.currencyFormat?.code,
        meta: {
          appDefId: APP_DEFINITION_ID,
          appInstanceId: this.siteStore.storeId,
          appInstance: this.siteStore.instanceManager.getInstance(),
          siteId: this.siteStore.msid,
          visitorId: this.siteStore.uuid as string,
          siteOwnerId: this.siteStore.ownerId,
          consumerWidgetId: StoresWidgetID.CART,
        },
        demoMode: this.siteStore.isEditorMode(),
        locale: this.siteStore.locale,
        domain: this.siteStore.location.baseUrl,
        orderItems: this.getOrderItems(),
        buttonStyle: this.getButtonStyle(),
        getButtonTheme: this.getButtonTheme(),
        shouldRequestShipping: this.shouldRequestShipping(),
        canCheckout:
          this.siteStore.experiments?.enabled(SPECS.UseExpressCheckoutButtonsLibraryForCart) &&
          this.checkIsAllowedToCheckout()?.canCheckout,
      },
    };
  }

  private getProductNameWithOptions(item: ElementOf<ICart['items']>) {
    const getTitleAndValueString = (option: {title: string; value: string}) => `${option.title}: ${option.value}`;
    const hasOptions = item.optionsSelectionsValues.length > 0;

    return `${item.product.name}${hasOptions ? ', ' : ''}${item.optionsSelectionsValues
      .map(getTitleAndValueString)
      .join(', ')}${hasFreeText(item) ? ', ' : ''}${
      hasFreeText(item) ? item.freeText.map(getTitleAndValueString).join(', ') : ''
    }`;
  }

  private getOrderItems() {
    return this.cartService.cart.items.map((item) => ({
      id: item.cartItemId.toString(),
      name: this.getProductNameWithOptions(item),
      price: item.convertedPrices.price.toString(),
      quantity: item.quantity,
      category: item.product.productType as 'physical' | 'digital',
    }));
  }

  public toProps(): Partial<ReturnType<CashierExpressStore['getProps']>> {
    if (this.cashierExpressEnabled) {
      return this.getProps();
    }

    return {
      shouldShowDynamicPaymentOptions: false,
      handlePaymentMethodError: this.handlePaymentMethodError,
    };
  }
}

/* istanbul ignore next */
function hasOnlyStoresItems(cart: ICart) {
  return (
    cart.items.filter(({catalogAppId}) => ![APP_DEFINITION_ID, STORES_APP_DEF_ID].includes(catalogAppId)).length === 0
  );
}
